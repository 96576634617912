import React from 'react';
import BulletPointsFormatter from '../../../../util/formatters/bulletPointsFormatter/bulletPointsFormatter';
import { useParams } from 'react-router-dom';

const PackList = ({ packListEn, packListDe, packList }) => {
  const { lang } = useParams()

  const packListToRender =
  lang === 'de' && packListDe !== 'Übersetzung hinzufügen' ? (
      <BulletPointsFormatter text={packListDe} />
    ) : (
      <BulletPointsFormatter text={packListEn} />
    );

  return <div>{packListToRender}</div>;
};

export default PackList;
