import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import css from './bookingDetailsNavbar.module.css';

export const BookingDetailsNavbar = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const changeCss = () => {
      if (typeof window !== 'undefined' && window?.document) {
        const position = document?.documentElement?.scrollTop || document?.body?.scrollTop || 0;
        setIsVisible(position > 1650);
      }
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('wheel', changeCss, false);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('wheel', changeCss);
      }
    };
  }, []);

  const scrollToPanel = () => {
    if (typeof window !== 'undefined' && window.document) {
      document.body.scrollTo({
        top: 550,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className={css.bookingDetailsNavbar}>
      {isVisible && (
        <button className={css.noReviewsBtnTop} onClick={scrollToPanel}>
          <FormattedMessage id="ListingsPage.Reviews.GoogleBtn" />
        </button>
      )}
    </div>
  );
};
