import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import Modal from '../Utils/Modal/Modal.jsx';
import { FormattedMessage } from 'react-intl';
import { ReviewsList } from './ReviewsList.jsx';
import css from './Reviews.module.css';
import ReviewRatings from '../ReviewRatings/ReviewRatings.jsx';

const Reviews = ({
  reviews = [],
  googleReviews: initialGoogleReviews = [],
  currentListing,
  rating,
  currentLanguage,
  showModalFromOutside = () => {},
  closeModalFromOutside,
  displayBothReviews = false,
  showText = false,
  showDot = false,
  isModalOpen = false,
}) => {
  const [state, setState] = useState({
    reviewsAmount: '',
    socialbnbRating: 0,
    finalRating: 0,
    isfetchDone: false,
    reviews: [],
    googleReviews: [],
    expandedReviews: [],
    expandedDrawerReviews: [],
    visibleReviewsCount: isMobile ? 2 : 4,
    visibleGoogleReviewsCount: isMobile ? 2 : 4,
    showModal: false,
    showGoogleModal: false,
    isGoogleReviews: false,
    allReviews: [],
    totalReviewsCount: 0,
    oldRating: currentListing.attributes.publicData.location?.rating,
    oldTotalReviews: currentListing.attributes.publicData.location?.totalGoogleReviews,
  });

  useEffect(() => {
    const googleReviewsFinal = getGoogleReviewsData();
    const googleReviewsRating = getGoogleReviewsRating();

    if (displayBothReviews) {
      // When displayBothReviews is true, combine both review types
      const combinedReviews = [...reviews, ...googleReviewsFinal];
      const totalReviewsCount = reviews.length + googleReviewsFinal.length;
      setState(prev => ({
        ...prev,
        reviews: combinedReviews,
        reviewsAmount: { value: combinedReviews },
        expandedReviews: Array(combinedReviews.length).fill(false),
        googleReviews: [],
        isfetchDone: true,
        finalRating: calculateRating(reviews, googleReviewsFinal, googleReviewsRating),
        isGoogleReviews: false,
        totalReviewsCount,
      }));
    } else {
      // Original logic for separate reviews
      const oldRating = currentListing.attributes.publicData.location?.rating;
      const oldTotalReviews = currentListing.attributes.publicData.location?.totalGoogleReviews;
      updateStateWithReviews(
        reviews,
        googleReviewsFinal,
        googleReviewsRating,
        oldRating,
        oldTotalReviews
      );
    }
  }, [reviews, initialGoogleReviews, displayBothReviews]);

  const calculateRating = (platformReviews, googleReviews, googleRating) => {
    if (platformReviews.length > 0) {
      const ratings = platformReviews.map(review => review.attributes.rating);
      const average = ratings.reduce((a, b) => a + b, 0) / ratings.length;
      return +average.toFixed(1);
    } else if (googleReviews.length > 0) {
      return +googleRating;
    }
    return 0;
  };

  const getGoogleReviewsData = () => {
    return initialGoogleReviews?.length
      ? initialGoogleReviews
      : currentListing?.attributes?.publicData?.location?.reviews?.length
      ? currentListing?.attributes?.publicData?.location?.reviews
      : currentListing?.attributes?.publicData?.googleReviews?.length
      ? currentListing?.attributes?.publicData?.googleReviews
      : [];
  };

  const getGoogleReviewsRating = () => {
    if (typeof rating === 'number' && rating !== 0) return rating;
    if (
      typeof currentListing?.attributes?.publicData?.location?.rating === 'number' &&
      currentListing?.attributes?.publicData?.location?.rating !== 0
    ) {
      return currentListing.attributes.publicData.location.rating;
    }
    if (typeof currentListing?.attributes?.publicData?.googleRating === 'number') {
      return currentListing.attributes.publicData.googleRating;
    }
    return 0;
  };

  const updateStateWithReviews = (
    reviews,
    googleReviews,
    googleRating,
    oldRating,
    oldTotalReviews
  ) => {
    if (reviews.length > 0) {
      const ratings = reviews.map(review => review.attributes.rating);
      const average = ratings.reduce((a, b) => a + b, 0) / ratings.length;

      setState(prev => ({
        ...prev,
        reviewsAmount: { value: reviews },
        socialbnbRating: average.toFixed(1),
        finalRating: +average.toFixed(1),
        reviews,
        googleReviews,
        isfetchDone: true,
        isGoogleReviews: false,
        expandedReviews: Array(reviews.length).fill(false),
        expandedDrawerReviews: Array(reviews.length).fill(false),
        oldRating,
        oldTotalReviews,
      }));
    } else if (reviews.length === 0 && googleReviews.length > 0) {
      setState(prev => ({
        ...prev,
        reviewsAmount: { value: googleReviews },
        finalRating: +googleRating,
        reviews: [],
        googleReviews,
        isGoogleReviews:
          currentListing?.attributes?.publicData?.location?.totalGoogleReviews ?? false,
        expandedReviews: Array(googleReviews.length).fill(false),
        expandedDrawerReviews: Array(googleReviews.length).fill(false),
        oldRating,
        oldTotalReviews,
      }));
    } else {
      setState(prev => ({
        ...prev,
        finalRating: 0,
        reviewsAmount: { value: 0 },
        reviews: [],
        googleReviews: [],
        isGoogleReviews: false,
        expandedReviews: [],
        expandedDrawerReviews: [],
        oldRating,
        oldTotalReviews,
      }));
    }
  };

  const handleShowMoreReviews = () => {
    setState(prev => ({
      ...prev,
      showModal: true,
      visibleReviewsCount: prev.reviews.length,
    }));
  };

  const handleShowMoreGoogleReviews = () => {
    setState(prev => ({
      ...prev,
      showGoogleModal: true,
      visibleGoogleReviewsCount: prev.googleReviews.length,
    }));
  };

  const handleShowLess = (isGoogle = false) => {
    setState(prev => ({
      ...prev,
      [isGoogle ? 'visibleGoogleReviewsCount' : 'visibleReviewsCount']: isMobile ? 2 : 4,
      [isGoogle ? 'showGoogleModal' : 'showModal']: false,
      [isGoogle ? 'expandedDrawerReviews' : 'expandedReviews']: prev[
        isGoogle ? 'expandedDrawerReviews' : 'expandedReviews'
      ].map(() => false),
    }));
    closeModalFromOutside(false);
  };

  const handleExpand = (index, isGoogle = false) => {
    const expandKey = isGoogle ? 'expandedDrawerReviews' : 'expandedReviews';
    setState(prev => ({
      ...prev,
      [expandKey]: prev[expandKey].map((expanded, i) => (i === index ? !expanded : expanded)),
    }));
  };

  const scrollToTop = () => {
    if (typeof window !== 'undefined' && window?.document) {
      document.body.scrollTo({ top: 500, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      handleShowMoreReviews();
    }
  }, [isModalOpen]);

  return (
    <div>
      <div className={css.reviewsWrapper}>
        <ReviewRatings
          currentListing={currentListing}
          showDot={showDot}
          showText={showText}
          textColor={null}
          onShowModalFromOutside={handleShowMoreReviews}
          fallBackReviews={reviews}
        />{' '}
      </div>
      {state.reviews.length > 0 && (
        <div data-elbaction={`visible:vis reviews-${state.reviews.length}`}>
          {state.showModal && (
            <Modal
              isOpen={handleShowMoreReviews}
              onClose={() => handleShowLess(false)}
              classNameButton="btnReviews"
              isReviewModal={true}
            >
              <ReviewsList
                reviews={state.reviews}
                visibleCount={state.visibleReviewsCount}
                expanded={state.expandedReviews}
                onExpand={index => handleExpand(index, false)}
                isGoogleReview={displayBothReviews ? undefined : false}
                currentLanguage={currentLanguage}
              />
            </Modal>
          )}

          <div className={css.reviewsGeneralContainer}>
            <ReviewsList
              reviews={state.reviews}
              visibleCount={state.visibleReviewsCount}
              expanded={state.expandedReviews}
              onExpand={index => handleExpand(index, false)}
              isGoogleReview={displayBothReviews ? undefined : false}
              currentLanguage={currentLanguage}
            />
          </div>

          {state.reviews.length > 4 ? (
            <button
              className={state.reviews.length < 3 ? `${css.openBtn} ${css.openBtn2}` : css.openBtn}
              onClick={handleShowMoreReviews}
              data-elbaction="click:click morereviews"
            >
              <FormattedMessage id="ListingsPage.Reviews" />
            </button>
          ) : (
            <button className={css.noReviewsBtn} onClick={scrollToTop}>
              <FormattedMessage id="ListingsPage.Reviews.GoogleBtn" />
            </button>
          )}
        </div>
      )}
      {!displayBothReviews && state.reviews.length === 0 && state.googleReviews.length > 0 ? (
        <div data-elbaction={`visible:vis reviews-${state.googleReviews.length}`}>
          {state.showGoogleModal ? (
            <Modal
              isOpen={handleShowMoreGoogleReviews}
              onClose={() => handleShowLess(true)}
              classNameButton="btnReviews"
              isReviewModal={true}
            >
              <ReviewsList
                reviews={state.googleReviews}
                visibleCount={state.visibleGoogleReviewsCount}
                expanded={state.expandedDrawerReviews}
                onExpand={index => handleExpand(index, true)}
                isGoogleReview={true}
                currentLanguage={currentLanguage}
              />
            </Modal>
          ) : (
            <></>
          )}

          <div className="reviewsGeneralContainer">
            <ReviewsList
              reviews={state.googleReviews}
              visibleCount={state.visibleGoogleReviewsCount}
              expanded={state.expandedDrawerReviews}
              onExpand={index => handleExpand(index, true)}
              isGoogleReview={true}
              currentLanguage={currentLanguage}
            />
          </div>

          <div className="noReviewsContainer">
            <FormattedMessage id="ListingsPage.Reviews.Google" />
            <button className={css.noReviewsBtn} onClick={scrollToTop}>
              <FormattedMessage id="ListingsPage.Reviews.GoogleBtn" />
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Reviews;
