import React, { useState } from 'react';
import { FormattedMessage } from '../../../../util/reactIntl';
import css from './HowTogetThere.module.css';
import Modal from '../Utils/Modal/Modal';
import BulletPointsFormatter from '../../../../util/formatters/bulletPointsFormatter/bulletPointsFormatter';
import { useParams } from 'react-router-dom';

const HowToGetThere = ({ howToGetThereEn, howToGetThereDe }) => {
  const { lang } = useParams();
  const [showFullText, setShowFullText] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleShowFullText = () => {
    setShowFullText(!showFullText);
    setIsModalOpen(!isModalOpen);
  };
  const howToGetThereText =
    lang === 'de' && howToGetThereDe !== 'Übersetzung hinzufügen'
      ? howToGetThereDe
      : howToGetThereEn;

  const howToGetThereTextChecked =
    typeof howToGetThereText === 'string' && howToGetThereText.length > 0 ? howToGetThereText : '';

  const arrayOfStrings = howToGetThereTextChecked
    ? howToGetThereTextChecked.split('. ').filter(Boolean)
    : [];

  const shortenedText = arrayOfStrings.slice(0, 4).join('.');
  const sentencesWithDot = arrayOfStrings.map(sentence => sentence.trim() + '.');

  const formattedShotSentece = <BulletPointsFormatter text={shortenedText + '...'} />;
  const formattedFullSentece = <BulletPointsFormatter text={howToGetThereText} />;
  return (
    <div
      className={`${css.howToGetThere} ${showFullText ? css.showFullText : ''}`}
      data-elbaction="visible:vis howtogetthere"
    >
      <h2>
        <FormattedMessage id="EditListingLocationForm.arrival" />
      </h2>
      {arrayOfStrings.length > 4 ? (
        showFullText ? (
          <>
            {isModalOpen && (
              <Modal
                isOpen={isModalOpen}
                onClose={toggleShowFullText}
                classNameButton="buttonHowToGetThere"
              >
                <h2>
                  <FormattedMessage id="EditListingLocationForm.arrival" />
                </h2>
                <div style={{ whiteSpace: 'pre-wrap', fontSize: 'medium' }}>{formattedFullSentece}</div>
              </Modal>
            )}
          </>
        ) : (
          <>
            <div className={css.shortenedText}>{formattedShotSentece}</div>
            <button className={css.btn} onClick={toggleShowFullText}>
              <p className={css.btnText}>
                {' '}
                <FormattedMessage id="EditListingOffersForm.activities.showMore" />
              </p>
              <p className={css.Gt}>&gt;</p>
            </button>
          </>
        )
      ) : (
        <div style={{ whiteSpace: 'pre-wrap' }}>{formattedFullSentece}</div>
      )}
    </div>
  );
};

export default HowToGetThere;
