import React from 'react';
import { FormattedMessage } from '../../../../util/reactIntl';
import IconReviewSearchPage from '../ReviewRatings/IconReviewSearchPage/iconReviewSearchPage';
import avatarEmpty from '../../../../assets/icons/avatar.png';
import css from './Reviews.module.css';
import googleIcon from '../../../../assets/google-icon.webp';

export const ReviewCard = ({ review, isGoogleReview, expanded, onExpand, currentLanguage }) => {
  const maxWordsToShow = 20;

  const getReviewContent = () => {
    return review?.text || review?.text_de || review?.attributes?.content || '';
  };

  const getFormattedDate = () => {
    const date = review?.time
      ? new Date(review.time * 1000)
      : review?.attributes?.createdAt
      ? new Date(review.attributes.createdAt)
      : null;

    if (date) {
      const month = date.toLocaleString('en', { month: 'long' });
      return `${month} ${date.getFullYear()}`;
    }

    return '';
  };

  const getAuthorImage = () => {
    return (
      review?.profile_photo_url ||
      review?.author?.profileImage?.attributes?.variants['square-small']?.url ||
      avatarEmpty
    );
  };

  const getAuthorName = () => {
    const displayName = (
      review?.author_name ||
      review?.author?.attributes?.profile?.displayName ||
      ''
    ).split(' ')[0];
    return displayName.length > 8 ? displayName.slice(0, 8) + '...' : displayName;
  };

  const getRating = () => {
    return review?.rating || review?.attributes?.rating || 0;
  };

  const content = getReviewContent();
  const wordCount = content?.split(' ').length || 0;
  const shouldShowMore = wordCount > maxWordsToShow;

  return (
    <div className={css.reviewsContainer} onClick={onExpand}>
      <div className={css.reviewsContainerLeft}>
        <img className={css.avatarImg} src={getAuthorImage()} alt="Review author avatar" />
        <div className={css.reviewsContainerLeftText}>
          <p className={css.name}>
            {review?.profile_photo_url ? (
              <img src={googleIcon} alt="Google Icon" className={css.googleIcon} />
            ) : (
              <></>
            )}{' '}
            {getAuthorName()}
          </p>
          <p className={css.date}>{getFormattedDate()}</p>
          {(review?.rating || review?.attributes?.rating) && (
            <div className={css.singleReviewRating}>
              <IconReviewSearchPage
                averageRating={review?.rating || review?.attributes?.rating}
                showDot={false}
                textColor={null}
              />
            </div>
          )}
        </div>
      </div>
      <div className={css.containerRight}>
        <p className={`${css.content} ${expanded ? css.expandedContent : css.showLines}`}>
          {content}
        </p>
        {shouldShowMore && (
          <div className={css.btnTextContainer}>
            <div className={css.btnText}>
              <div className={css.showMoreLessText}>
                <FormattedMessage
                  id={expanded ? 'faq.showLess' : 'EditListingOffersForm.activities.showMore'}
                />
                <span className={css.Gt}>{expanded ? '<' : '>'}</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
