import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './cancellationPolicy.module.css';

const CancellationPolicy = () => {
  return (
    <div className={styles.cancellationContainer}>
      <h2 className={styles.title}>
        <FormattedMessage id="editlistingPage.cancellationLabel" />
      </h2>
      <div className={styles.policyContent}>
        <ul className={styles.policyList}>
          <li className={styles.policyItem}>
            <FormattedMessage id="experienceListing.common.cancellationText1" />
          </li>
          <li className={styles.policyItem}>
            <FormattedMessage id="experienceListing.common.cancellationText2" />
          </li>
          <li className={styles.policyItem}>
            <FormattedMessage id="experienceListing.common.cancellationText3" />
          </li>
          <li className={styles.policyItem}>
            <FormattedMessage id="experienceListing.common.cancellationText4" />
          </li>
          <li className={styles.policyItem}>
            <FormattedMessage id="experienceListing.common.cancellationText5" />
          </li>
          <li className={styles.policyItem}>
            <FormattedMessage id="experienceListing.common.cancellationText6" />
            <a
              href="https://www.socialbnb.org/en/p/agb"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.termsLink}
            >
              <FormattedMessage id="experienceListing.common.cancellationText6.link" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default CancellationPolicy;
