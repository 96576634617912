import React, { useState, useEffect } from 'react';
import css from './ListingDetails.module.css';
import { FormattedMessage } from '../../../../util/reactIntl';
import animalIcon from '../../../../assets/icons/projectTypesIcon/animal.png';
import natureIcon from '../../../../assets/icons/projectTypesIcon/environment.png';
import equalityIcon from '../../../../assets/icons/projectTypesIcon/equality.png';
import healthIcon from '../../../../assets/icons/projectTypesIcon/health.png';
import sportsIcon from '../../../../assets/icons/projectTypesIcon/sports.png';
import educationIcon from '../../../../assets/icons/projectTypesIcon/education.png';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import Modal from '../Utils/Modal/Modal';
import AvatarMedium from '../../../../components/Avatar/Avatar';
import ReviewRatings from '../../ListingPageComponents/ReviewRatings/ReviewRatings';
import { PrimaryButton } from '../../../../components';
import NamedLinkNewTab from '../../../../components/NamedLink/NamedLinkNewTab';

const ListingDetailsHeader = props => {
  const [isMobile, setIsMobile] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal open/close state
  const { lang } = useParams();
  const {
    peopleAmount,
    food,
    amenities,
    category,
    roomTypeRaw: roomType,
  } = props.currentListing.attributes.publicData;
  const hostNameRaw = props.currentListing.author.attributes.profile.displayName;
  const hostFirstName = hostNameRaw.split(' ')[0];
  const intl = useIntl();
  const yourHost = intl.formatMessage({ id: 'ListingDetails.yourHost' });
  const history = useHistory();
  let accomodationTypeTranslationId = '';
  if (props.projectAccomodationType !== null) {
    accomodationTypeTranslationId = props.projectAccomodationType.props.id;
  }

  let currentRoomtypeTranslationId = '';
  if (props.ProjectRoomtype) {
    currentRoomtypeTranslationId = props.ProjectRoomtype.props.id;
  }
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen); // Toggle modal open state
  };

  const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
  }, []);

  const charValues = [
    {
      value: 'Tierschutz',
      text: <FormattedMessage id="filter.category.animalCare" />,
      icon: animalIcon,
    },
    {
      value: 'Naturschutz',
      text: <FormattedMessage id="filter.category.environment" />,
      icon: natureIcon,
    },

    {
      value: 'Equality',
      text: <FormattedMessage id="filter.category.equality" />,
      icon: equalityIcon,
    },

    {
      value: 'Health',
      text: <FormattedMessage id="filter.category.health" />,
      icon: healthIcon,
    },

    {
      value: 'Sports',
      text: <FormattedMessage id="filter.category.sports" />,
      icon: sportsIcon,
    },
    {
      value: 'Bildung',
      text: <FormattedMessage id="filter.category.education" />,
      icon: educationIcon,
    },
  ];

  const GetCharacteristics = () => {
    const firstChar = category && getCorrectChar(category[0]);
    const secondChar = category && getCorrectChar(category[1]);

    return (
      <div className={css.characteristicsWrapper}>
        <div className={css.characteristics}>
          <img
            style={{ height: '30px', width: '30px' }}
            src={firstChar?.icon}
            alt="Project characteristics"
          />
          <span>
            <p className={css.charTxt}>{firstChar?.text}</p>
          </span>
        </div>
        {secondChar && secondChar.icon && (
          <div className={css.characteristics}>
            <img
              style={{ height: '30px', width: '30px' }}
              src={secondChar.icon}
              alt="Project characteristics"
            />
            <span>
              <p className={css.charTxt}>{secondChar.text}</p>
            </span>
          </div>
        )}
      </div>
    );
  };

  const getCorrectChar = c => {
    let correctData = {};

    if (c) {
      for (let i = 0; i < charValues.length; i++) {
        if (charValues[i].value == c) {
          correctData = charValues[i];
        }
      }
    }
    return correctData;
  };

  const GetIncludedFood = () => {
    const meals = [
      'breakfast_inclusive',
      'lunch_inclusive',
      'dinner_inclusive',
      'coffee_tee',
      'vegetarian_options',
      'vegan_options',
    ];

    const hasEnoughMeals = food ? meals.filter(meal => food.includes(meal)).length > 4 : false;

    if (!hasEnoughMeals) {
      const foodData = [
        {
          id: 'vegetarian_options',
          text: <FormattedMessage id="ListingDetails.food.veg" />,
        },
        {
          id: 'vegan_options',
          text: <FormattedMessage id="ListingDetails.food.vegan" />,
        },
        {
          id: 'coffee_tee',
          text: <FormattedMessage id="ListingDetails.food.coffeeTea" />,
        },
        {
          id: 'dinner_inclusive',
          text: <FormattedMessage id="ListingDetails.food.dinner" />,
        },
        {
          id: 'breakfast_inclusive',
          text: <FormattedMessage id="ListingDetails.food.breakfast" />,
        },
        {
          id: 'lunch_inclusive',
          text: <FormattedMessage id="ListingDetails.food.lunch" />,
        },
      ];
      const includedFoodItems = food ? foodData.filter(item => food.includes(item.id)) : [];

      return (
        <>
          {includedFoodItems.map(item => (
            <div key={item.id} className={css.subSections}>
              <span>&#8226;</span>
              <span> {item.text}</span>
            </div>
          ))}
        </>
      );
    }

    return (
      <>
        <div className={css.subSections}>
          <span>&#8226;</span>
          <span>
            {' '}
            <FormattedMessage id="ListingDetails.food.allMeals" />
          </span>
        </div>
      </>
    );
  };
  const GetAmenities = () => {
    let bathroomSituation = null;

    if (amenities) {
      for (let i = 0; i < amenities.length; i++) {
        if (amenities[i] === 'privat_bathroom') {
          bathroomSituation = (
            <FormattedMessage id="ListingDetails.bathroomSituation.prv_bathroom" />
          );
          break;
        }
        if (amenities[i] === 'shared_bathroom') {
          bathroomSituation = (
            <FormattedMessage id="ListingDetails.bathroomSituation.sh_bathroom" />
          );
          break;
        }
        if (amenities[i] === 'private_kitchen') {
          bathroomSituation = <FormattedMessage id="ListingDetails.bathroomSituation.kitchen" />;
        }
      }
    }

    return (
      <>
        {bathroomSituation && (
          <div className={css.subSections}>
            {' '}
            <span>&#8226;</span>
            <span> {bathroomSituation}</span>
          </div>
        )}
      </>
    );
  };
  let accomodationTypeRaw = props?.currentListing?.attributes?.publicData?.accomodationtype ?? '';
  const projectAccomodationType =
    accomodationTypeRaw === 'bnb' || accomodationTypeRaw === 'B&B'
      ? intl.formatMessage({ id: 'accomodationDisplay.B&B' })
      : accomodationTypeRaw === 'glamping'
      ? intl.formatMessage({ id: 'accomodationDisplay.glamping' })
      : accomodationTypeRaw === 'hostel'
      ? intl.formatMessage({ id: 'accomodationDisplay.hostel' })
      : accomodationTypeRaw === 'hotel'
      ? intl.formatMessage({ id: 'accomodationDisplay.hotel' })
      : accomodationTypeRaw === 'homestay'
      ? intl.formatMessage({ id: 'accomodationDisplay.homestay' })
      : accomodationTypeRaw === 'holiday'
      ? intl.formatMessage({ id: 'accomodationDisplay.holiday' })
      : accomodationTypeRaw === 'apartment'
      ? intl.formatMessage({ id: 'accomodationDisplay.apartment' })
      : accomodationTypeRaw === 'tinyHouse'
      ? intl.formatMessage({ id: 'accomodationDisplay.tinyHouse' })
      : accomodationTypeRaw === 'camping'
      ? intl.formatMessage({ id: 'accomodationDisplay.camping' })
      : accomodationTypeRaw === 'banglow'
      ? intl.formatMessage({ id: 'accomodationDisplay.banglow' })
      : null;

  let roomTypeRaw = props?.roomTypeRaw ?? '';

  //is stay
  const projectRoomtype =
    roomTypeRaw == null
      ? null
      : props.roomTypeRaw === 'singlebedroom'
      ? intl.formatMessage({ id: 'roomtypes.singlebedroom' })
      : props.roomTypeRaw === 'twobedroom'
      ? intl.formatMessage({ id: 'roomtypes.twobedroom' })
      : roomTypeRaw === 'doublebedroom' || roomTypeRaw === 'doubleRoom'
      ? intl.formatMessage({ id: 'roomtypes.doublebedroom' })
      : props.roomTypeRaw === 'shared_bedroom'
      ? intl.formatMessage({ id: 'roomtypes.shared_bedroom' })
      : props.roomTypeRaw === 'multi_bedroom'
      ? intl.formatMessage({ id: 'roomtypes.multi_bedroom' })
      : props.roomTypeRaw === 'entire_accomodation'
      ? intl.formatMessage({ id: 'roomtypes.entire_accomodation' })
      : props.roomTypeRaw === 'tent'
      ? intl.formatMessage({ id: 'roomtypes.tent' })
      : null;

  //is experience
  const experienceType = props?.currentListing?.attributes?.title.split('• ')[1];
  const experienceTitle = 
  experienceType === '2 Days 1 Night' 
    ? intl.formatMessage({ id: 'experienceTitle.2d1n' }) 
    : experienceType === '3 Days 2 Nights' 
    ? intl.formatMessage({ id: 'experienceTitle.3d2n' }) 
    : experienceType === '4 Days 3 Nights' 
    ? intl.formatMessage({ id: 'experienceTitle.4d3n' }) 
    : experienceType === '5 Days 4 Nights' 
    ? intl.formatMessage({ id: 'experienceTitle.5d4n' }) 
    : experienceType === '6 Days 5 Nights' 
    ? intl.formatMessage({ id: 'experienceTitle.6d5n' }) 
    : experienceType === '7 Days 6 Nights' || experienceType === '1 Week' 
    ? intl.formatMessage({ id: 'experienceTitle.1w' }) 
    : experienceType === '8 Days 7 Nights' 
    ? intl.formatMessage({ id: 'experienceTitle.8d7n' }) 
    : experienceType === '9 Days 8 Nights' 
    ? intl.formatMessage({ id: 'experienceTitle.9d8n' }) 
    : experienceType === '10 Days 9 Nights' 
    ? intl.formatMessage({ id: 'experienceTitle.10d9n' })
    : experienceType === '11 Days 10 Nights'
    ? intl.formatMessage({ id: 'experienceTitle.11d10n' })
    : experienceType === '12 Days 11 Nights'
    ? intl.formatMessage({ id: 'experienceTitle.12d11n' })
    : experienceType === '13 Days 12 Nights'
    ? intl.formatMessage({ id: 'experienceTitle.13d12n' })
    : experienceType === '14 Days 13 Nights' || experienceType === '2 Weeks' 
    ? intl.formatMessage({ id: 'experienceTitle.2w' }) 
    : experienceType === '21 Days 20 Nights' || experienceType === '3 Weeks' 
    ? intl.formatMessage({ id: 'experienceTitle.3w' }) 
    : null;
    
  const overnightStay = intl.formatMessage({ id: 'listingDetails.overnightstay' });
  const overnightStayExperience = intl.formatMessage({
    id: 'listingDetails.overnightstayExperience',
  });
  const hostID = props.currentListing.author.id.uuid;
  // Host Modal content
  const hostModalContent = (
    <div className={css.modalContentWrapper}>
      <div className={css.modalLeftSection}>
        <AvatarMedium
          className={css.avatarMedium}
          user={props.currentListing.author}
          disableProfileLink
        />
        <div onClick={() => toggleModal()}>
          {/* TODO: Accumulate all reviews at some point*/}
          <ReviewRatings
            isHostModal={true}
            currentListing={props.currentListing}
            reviews={props.reviews}
            googleReviews={props.currentListing.attributes.publicData.location.reviews}
            rating={props.currentListing.attributes.publicData.location.rating}
            onShowModalFromOutside={props.openModalFromOutside}
            showText={true}
          />
        </div>
      </div>
      <div className={css.modalRightSection}>
        <h2>
          <FormattedMessage id="ProfilePage.desktopHeading" values={{ name: hostFirstName }} />
        </h2>
        <p>
          {lang == 'en'
            ? props.currentListing.author.attributes.profile.publicData.aboutTheHost
            : props.currentListing.author.attributes.profile.publicData.aboutTheHost_de}
        </p>

        <NamedLinkNewTab
          name="ProfilePage"
          params={{
            slug: '',
            id: hostID,
          }}
        >
          <PrimaryButton className={css.viewProfileButton} onClick={() => history.push()}>
            <FormattedMessage id="UserCard.viewProfileLink" />
          </PrimaryButton>
        </NamedLinkNewTab>
      </div>
    </div>
  );

  return (
    <div className={css.listingDetailsWrapper}>
      <div className={css.listingInfoWrapper}>
        <div className={css.titleNcharacteristics}>
          <h2 style={{ display: 'flex' }}>
            <span>
              {props.isExperienceType
                ? experienceTitle + ',  ' + yourHost + ' '
                : projectRoomtype + ' ' + projectAccomodationType + ', ' + yourHost + ' '}
              &nbsp;
              {isModalOpen && (
                <Modal isOpen={isModalOpen} onClose={toggleModal} isHostModal={true}>
                  {hostModalContent}
                </Modal>
              )}
              <span className={css.hostName} onClick={toggleModal}>
                {hostFirstName}
              </span>
            </span>
          </h2>
          <div className={css.accomodationFeatures}>
            {!props.isExperienceType ? (
              <>
                <div>
                  <span className={css.person}>
                    {peopleAmount}
                    <FormattedMessage
                      id={peopleAmount > 1 ? 'ListingDetails.persons' : 'ListingDetails.person'}
                    />
                  </span>
                </div>
                <GetIncludedFood />
                <GetAmenities />
              </>
            ) : (
              <>{overnightStayExperience + projectAccomodationType}</>
            )}
          </div>
        </div>

        <hr />

        <div className={css.charWrapper}>
          <GetCharacteristics />
        </div>
      </div>
    </div>
  );
};

export default ListingDetailsHeader;
