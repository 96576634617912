import React from 'react';
import css from './Languages.module.css';

import langImg from '../../../../assets/icons/languages.webp';
import { useParams } from 'react-router-dom';

const Languages = ({ languages, otherLanguagesEn, otherLanguagesDe }) => {
  const englishNames = ['english', 'spanish', 'french', 'german', 'portuguese', 'chinese'];
  const germanNames = [
    'Englisch',
    'Spanisch',
    'Französisch',
    'Deutsch',
    'Portugiesisch',
    'Chinesisch',
  ];

  const { lang } = useParams()

  let transformedLanguages = [];
  let otherLanguages =
    lang === 'en'
      ? otherLanguagesEn?.length > 0
        ? otherLanguagesEn
        : otherLanguagesDe?.length > 0
        ? otherLanguagesDe
        : []
      : otherLanguagesDe?.length > 0
      ? otherLanguagesDe
      : otherLanguagesDe?.length > 0
      ? otherLanguagesDe
      : [];
  if (lang === 'de') {
    transformedLanguages = languages.map(name => {
      const index = englishNames.indexOf(name.toLowerCase());
      /*  if (otherLanguagesDe !== 'Übersetzung hinzufügen') {
        otherLanguages = otherLanguagesDe?.length > 0 ? otherLanguagesDe : otherLanguagesEn;
      } */

      return index !== -1 ? germanNames[index] : name;
    });
  } else if (lang === 'en') {
    transformedLanguages = languages.map(name => {
      const index = englishNames.indexOf(name);
      /*       if (otherLanguagesEn.length > 0) {
        otherLanguages = otherLanguagesEn?.length > 0 ? otherLanguagesEn : otherLanguagesDe;
      } */
      return index !== -1
        ? englishNames[index].charAt(0).toUpperCase() + englishNames[index].slice(1)
        : name;
    });
  } else {
    transformedLanguages = [];
  }
  return (
    <div className={css.langContainer}>
      <img className={css.langImg} src={langImg} alt="languages all over the world" />
      <ul className={css.languageList}>
        {transformedLanguages.map((language, index) => (
          <li key={index}> {language}</li>
        ))}

        {otherLanguages.length >= 1 ? <li> {otherLanguages}</li> : <></>}
      </ul>
    </div>
  );
};

export default Languages;
