import React from 'react';
import BulletPointsFormatter from '../../../../util/formatters/bulletPointsFormatter/bulletPointsFormatter';
import { useParams } from 'react-router-dom';

const HouseRules = ({ houseRules, houseRulesDe }) => {
  const { lang } = useParams()

  const houseRulesChecked = houseRules && typeof houseRules === 'string' ? houseRules : '';
  const houseRulesCheckedDe = houseRulesDe && typeof houseRulesDe === 'string' ? houseRulesDe : '';

  const houseRulesToRender =
    lang === 'de' && houseRulesDe !== 'Übersetzung hinzufügen' ? (
      <>
        {' '}
        <BulletPointsFormatter text={houseRulesCheckedDe} />
      </>
    ) : (
      <>
        {' '}
        <BulletPointsFormatter text={houseRulesChecked} />
      </>
    );
  return <div>{houseRulesToRender}</div>;
};

export default HouseRules;
