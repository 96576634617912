import React, { useState, useEffect, useMemo } from 'react';
import { FormattedMessage } from '../../../../util/reactIntl';
import css from './Offerings.module.css';

// Components
import Food from '../OnSite/Food.jsx';
import Amenities from '../OnSite/Amenities';
import Surroundings from '../OnSite/Surroundings';
import Features from '../OnSite/Features';
import NotIncluded from '../OnSite/NotIncluded';

// Icons
import close from '../../../../assets/icons/close.webp';
import bank from '../../../../assets/icons/surroundings/Bank.webp';
import doctor from '../../../../assets/icons/surroundings/Doctor.webp';
import restaurant from '../../../../assets/icons/surroundings/Restaurant.webp';
import supermarket from '../../../../assets/icons/surroundings/Supermarket.webp';
import turanbieter from '../../../../assets/icons/surroundings/Touranbieter.webp';
import wmachine from '../../../../assets/icons/equipment/Waschküche_.webp';
import bus from '../../../../assets/icons/characteristics/Bus.webp';

import dinnerPaid from '../../../../assets/icons/meals/Abendessen-paid.webp';
import dinner from '../../../../assets/icons/meals/Abendessen.webp';
import breakfastPaid from '../../../../assets/icons/meals/Breakfast-paid.webp';
import breakfast from '../../../../assets/icons/meals/Breakfast.webp';
import coffe from '../../../../assets/icons/meals/Coffee_.webp';
import lunchPaid from '../../../../assets/icons/meals/Lunch-paid.webp';
import lunch from '../../../../assets/icons/meals/Lunch.webp';
import vegan from '../../../../assets/icons/meals/Vegan.webp';
import vegetarian from '../../../../assets/icons/meals/Vegetarian_.webp';

import bathroomShared from '../../../../assets/icons/equipment/Bathroom-Shared.webp';
import bathroom from '../../../../assets/icons/equipment/Bathroom.webp';

import Bettwäsche from '../../../../assets/icons/equipment/Bettwäsche_.webp';
import Computer from '../../../../assets/icons/equipment/Computer.webp';
import Dresser from '../../../../assets/icons/equipment/Dresser.webp';
import Electricity from '../../../../assets/icons/equipment/Electricity.webp';
import Handtücher from '../../../../assets/icons/equipment/Handtücher_.webp';
import Heater from '../../../../assets/icons/equipment/Heater.webp';
import Kitchen from '../../../../assets/icons/equipment/Kitchen.webp';
import Klimaanlage from '../../../../assets/icons/equipment/Klimaanlage.webp';
import Lampe from '../../../../assets/icons/equipment/Lampe.webp';
import Luggage from '../../../../assets/icons/equipment/Luggage.webp';
import Moskito from '../../../../assets/icons/equipment/Moskito.webp';
import Parking from '../../../../assets/icons/equipment/Parking.webp';
import Security_Locker from '../../../../assets/icons/equipment/Security_Locker.webp';
import SharedKitchen from '../../../../assets/icons/equipment/SharedKitchen.webp';
import Shower from '../../../../assets/icons/equipment/Shower.webp';
import Sink from '../../../../assets/icons/equipment/Sink.webp';
import Toilet from '../../../../assets/icons/equipment/Toilet.webp';
import Tv from '../../../../assets/icons/equipment/Tv.webp';
import Ventilator_ from '../../../../assets/icons/equipment/Ventilator_.webp';
import Waschküche_ from '../../../../assets/icons/equipment/Waschküche_.webp';
import Wifi from '../../../../assets/icons/equipment/Wifi.webp';
import adventure from '../../../../assets/icons/characteristics/adventure.webp';
import Animals from '../../../../assets/icons/characteristics/Animals.webp';
import barrierefreiheit from '../../../../assets/icons/characteristics/barrierefreiheit.webp';
import Beach from '../../../../assets/icons/characteristics/Beach.webp';
import Bus from '../../../../assets/icons/characteristics/Bus.webp';
import Family from '../../../../assets/icons/characteristics/Family.webp';
import Komfort from '../../../../assets/icons/characteristics/Komfort.webp';
import Nature from '../../../../assets/icons/characteristics/Nature.webp';
import Projektbesuch_ from '../../../../assets/icons/characteristics/Projektbesuch_.webp';
import Projektbesuch_Cancel from '../../../../assets/icons/characteristics/Projektbesuch_Cancel.webp';
import Sport from '../../../../assets/icons/characteristics/Sport.webp';
import Theatre from '../../../../assets/icons/characteristics/Theatre.webp';
import Trekking from '../../../../assets/icons/characteristics/Trekking.webp';

const Offerings = ({ currentListing, publicData }) => {
  const [isOpen, setIsOpen] = useState(false);

  // Extract data from publicData with default empty arrays
  const selectedFood = publicData?.food || [];
  const selectedSurroundings = publicData?.surroundings || [];
  const selectedAmenities = publicData?.amenities || [];
  const selectedFeatures = publicData?.characteristics || [];

  const numberAmenities =
    selectedFood.length +
    selectedAmenities.length +
    selectedSurroundings.length +
    selectedFeatures.length;

  // Memoize specificValues to prevent recreation on each render
  const specificValues = useMemo(
    () => [
      /*features*/
      {
        value: 'public_transport',
        text: <FormattedMessage id="filter.surroundings.public_transport" />,
        icon: bus,
      },
      {
        value: 'comfort',
        text: <FormattedMessage id="filter.characteristics.comfort" />,
        icon: Komfort,
      },
      {
        value: 'adventure',
        text: <FormattedMessage id="filter.characteristics.adventure" />,
        icon: adventure,
      },

      {
        value: 'nature',
        text: <FormattedMessage id="filter.characteristics.nature" />,
        icon: Nature,
      },

      {
        value: 'near_beach',
        text: <FormattedMessage id="filter.characteristics.near_beach" />,
        icon: Beach,
      },

      {
        value: 'animallovers',
        text: <FormattedMessage id="filter.characteristics.animallovers" />,
        icon: Animals,
      },

      {
        value: 'sportlovers',
        text: <FormattedMessage id="filter.characteristics.sportlovers" />,
        icon: Sport,
      },

      {
        value: 'familyfriendly',
        text: <FormattedMessage id="filter.characteristics.familyfriendly" />,
        icon: Family,
      },

      {
        value: 'trekking',
        text: <FormattedMessage id="filter.characteristics.trekking" />,
        icon: Trekking,
      },

      {
        value: 'culture',
        text: <FormattedMessage id="filter.characteristics.culture" />,
        icon: Theatre,
      },

      {
        value: 'barrier_free',
        text: <FormattedMessage id="filter.characteristics.barrier_free" />,
        icon: barrierefreiheit,
      },

      {
        value: 'accessibility',
        text: <FormattedMessage id="filter.characteristics.accessibility" />,
        icon: Bus,
      },

      {
        value: 'projecttours_possible',
        text: <FormattedMessage id="filter.characteristics.projecttours_possible" />,
        icon: Projektbesuch_,
      },

      {
        value: 'projecttours_impossible',
        text: <FormattedMessage id="filter.characteristics.projecttours_impossible" />,
        icon: Projektbesuch_Cancel,
      },

      //food
      {
        value: 'coffee_tee',
        text: <FormattedMessage id="filter.food.coffee_tee" />,
        icon: coffe,
      },
      {
        value: 'vegetarian_options',
        text: <FormattedMessage id="filter.food.vegetarian_options" />,
        icon: vegetarian,
      },
      {
        value: 'lunch_for_sale',
        text: <FormattedMessage id="filter.food.lunch_for_sale" />,
        icon: lunchPaid,
      },
      {
        value: 'breakfast_for_sale',
        text: <FormattedMessage id="filter.food.breakfast_for_sale" />,
        icon: breakfastPaid,
      },
      {
        value: 'dinner_for_sale',
        text: <FormattedMessage id="filter.food.dinner_for_sale" />,
        icon: dinnerPaid,
      },
      {
        value: 'vegan_options',
        text: <FormattedMessage id="filter.food.vegan_options" />,
        icon: vegan,
      },
      {
        value: 'dinner_inclusive',
        text: <FormattedMessage id="filter.food.dinner_inclusive" />,
        icon: dinner,
      },
      {
        value: 'breakfast_inclusive',
        text: <FormattedMessage id="filter.food.breakfast_inclusive" />,
        icon: breakfast,
      },
      {
        value: 'lunch_inclusive',
        text: <FormattedMessage id="filter.food.lunch_inclusive" />,
        icon: lunch,
      },

      {
        value: 'shared_bathroom',
        text: <FormattedMessage id="filter.amenities.shared_bathroom" />,
        icon: bathroomShared,
      },
      {
        value: 'privat_bathroom',
        text: <FormattedMessage id="filter.amenities.privat_bathroom" />,
        icon: bathroom,
      },

      {
        value: 'bedsheets',
        text: <FormattedMessage id="filter.amenities.bedsheets" />,
        icon: Bettwäsche,
      },
      {
        value: 'computer',
        text: <FormattedMessage id="filter.amenities.computer" />,
        icon: Computer,
      },
      {
        value: 'wardrobe',
        text: <FormattedMessage id="filter.amenities.wardrobe" />,
        icon: Dresser,
      },
      {
        value: 'electricity',
        text: <FormattedMessage id="filter.amenities.electricity" />,
        icon: Electricity,
      },
      {
        value: 'electricityNo',
        text: <FormattedMessage id="filter.amenities.electricity" />,
        icon: Electricity,
      },
      {
        value: 'towels',
        text: <FormattedMessage id="filter.amenities.towels" />,
        icon: Handtücher,
      },
      {
        value: 'heating',
        text: <FormattedMessage id="filter.amenities.heating" />,
        icon: Heater,
      },
      {
        value: 'private_kitchen',
        text: <FormattedMessage id="filter.amenities.private_kitchen" />,
        icon: Kitchen,
      },
      {
        value: 'air_conditioning',
        text: <FormattedMessage id="filter.amenities.air_conditioning" />,
        icon: Klimaanlage,
      },
      {
        value: 'light',
        text: <FormattedMessage id="filter.amenities.light" />,
        icon: Lampe,
      },
      {
        value: 'luggage_storage',
        text: <FormattedMessage id="filter.amenities.luggage_storage" />,
        icon: Luggage,
      },
      {
        value: 'mosquito_net',
        text: <FormattedMessage id="filter.amenities.mosquito_net" />,
        icon: Moskito,
      },
      {
        value: 'parking',
        text: <FormattedMessage id="filter.amenities.parking" />,
        icon: Parking,
      },
      {
        value: 'safe',
        text: <FormattedMessage id="filter.amenities.safe" />,
        icon: Security_Locker,
      },
      {
        value: 'shared_kitchen',
        text: <FormattedMessage id="filter.amenities.shared_kitchen" />,
        icon: SharedKitchen,
      },
      {
        value: 'shower',
        text: <FormattedMessage id="filter.amenities.shower" />,
        icon: Shower,
      },
      {
        value: 'sink',
        text: <FormattedMessage id="filter.amenities.sink" />,
        icon: Sink,
      },
      {
        value: 'toilet',
        text: <FormattedMessage id="filter.amenities.toilet" />,
        icon: Toilet,
      },
      {
        value: 'tv',
        text: <FormattedMessage id="filter.amenities.tv" />,
        icon: Tv,
      },
      {
        value: 'fan',
        text: <FormattedMessage id="filter.amenities.fan" />,
        icon: Ventilator_,
      },
      {
        value: 'laundry_facilities',
        text: <FormattedMessage id="filter.amenities.laundry_facilities" />,
        icon: Waschküche_,
      },
      {
        value: 'internet',
        text: <FormattedMessage id="filter.amenities.internet" />,
        icon: Wifi,
      },

      /*surroundings*/
      {
        value: 'bank',
        text: 'Bank',
        icon: bank,
      },
      {
        value: 'facility',
        text: <FormattedMessage id="filter.surroundings.facility" />,
        icon: doctor,
      },
      {
        value: 'restaurant',
        text: <FormattedMessage id="filter.surroundings.restaurant" />,
        icon: restaurant,
      },
      {
        value: 'supermarket',
        text: <FormattedMessage id="filter.surroundings.supermarket" />,
        icon: supermarket,
      },
      {
        value: 'tour',
        text: <FormattedMessage id="filter.surroundings.tour" />,
        icon: turanbieter,
      },
      {
        value: 'washing',
        text: <FormattedMessage id="filter.surroundings.washing" />,
        icon: wmachine,
      },
    ],
    []
  );

  const filteredFeatures = useMemo(() => {
    const getFilteredFeatures = () => {
      const result = [];

      const featurePriority = [
        'near_beach',
        'nature',
        'familyfriendly',
        'projecttours_possible',
        'comfort',
        'culture',
        'trekking',
        'barrier_free',
      ];

      for (const feature of featurePriority) {
        if (selectedFeatures.includes(feature)) {
          result.push(feature);
          break;
        }
      }

      if (selectedFood.length === 0) {
        for (const feature of featurePriority) {
          if (!result.includes(feature) && selectedFeatures.includes(feature)) {
            result.push(feature);
            break;
          }
        }

        // Add surrounding if no food
        const surroundingPriority = ['supermarket', 'bus', 'tour', 'restaurant', 'facility'];
        for (const surrounding of surroundingPriority) {
          if (selectedSurroundings.includes(surrounding)) {
            result.push(surrounding);
            break;
          }
        }
      } else {
        // Add food options based on priority
        const foodPriority = [
          ['vegan_options', 'vegetarian_options'],
          ['coffee_tee', 'breakfast_inclusive', 'lunch_inclusive', 'dinner_inclusive'],
        ];

        for (const priorityGroup of foodPriority) {
          for (const food of priorityGroup) {
            if (selectedFood.includes(food)) {
              result.push(food);
              break;
            }
          }
        }
      }

      // Add equipment
      if (!selectedAmenities.includes('electricity') && !selectedAmenities.includes('internet')) {
        result.push('electricityNo');
      } else {
        const equipmentPriority = [
          'internet',
          'parking',
          'laundry_facilities',
          'air_conditioning',
          'private_kitchen',
          'electricity',
        ];
        for (const equipment of equipmentPriority) {
          if (selectedAmenities.includes(equipment)) {
            result.push(equipment);
            break;
          }
        }
      }

      return result;
    };

    const filteredValues = getFilteredFeatures()
      .map(value => specificValues.find(item => item.value === value))
      .filter(Boolean)
      .slice(0, 4);

    return filteredValues;
  }, [selectedFeatures, selectedFood, selectedSurroundings, selectedAmenities, specificValues]);

  return (
    <div className={css.offeringsContainer} data-elbaction="visible:vis offerings">
      <h2>
        <FormattedMessage id="Offerings.title" />
      </h2>
      <ul className={css.specialSelectionContainer}>
        {filteredFeatures.map((item, index) => (
          <li
            key={index}
            className={
              item.value === 'electricityNo' ? css.specialSelectionCrossed : css.specialSelection
            }
          >
            <span>{item.text}</span>
            <img className={css.specialSelectionImg} src={item.icon} alt={item.text} />
          </li>
        ))}
      </ul>

      <button
        className={css.openBtn}
        onClick={() => setIsOpen(true)}
        data-elbaction="click:click moreamenities"
      >
        <FormattedMessage id="Offerings.showAll" values={{ numberAmenities }} />
      </button>

      {isOpen && (
        <div className={css.modalDrawer}>
          <div className={css.modalOverlay} onClick={() => setIsOpen(false)} />
          <div className={css.modalContent}>
            <div className={css.closeBtnContainer}>
              <button className={css.closeBtn} onClick={() => setIsOpen(false)}>
                <img className={css.closeBtnImg} src={close} alt="close button" />
              </button>
            </div>

            <div className={css.modalContentElements}>
              {publicData && <Food food={selectedFood} />}
              {publicData && <Amenities amenities={selectedAmenities} />}
              {publicData && <Surroundings surroundings={selectedSurroundings} />}
              {publicData && <Features features={selectedFeatures} />}
              {publicData && (
                <NotIncluded
                  food={selectedFood}
                  amenities={selectedAmenities}
                  surroundings={selectedSurroundings}
                  features={selectedFeatures}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Offerings;
