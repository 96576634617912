import React from 'react';
import css from './bulletPointsFormatter.module.css';

const suspiciousDomains = ['example-malicious-site.com', 'suspicious-domain.net'];
const suspiciousExtensions = ['.exe', '.bat', '.msi', '.scr'];
const suspiciousPathPatterns = ['/download', '/free-offer', '/click-here'];
const suspiciousProtocols = ['ftp:', 'file:'];
const urlShorteners = ['bit.ly', 'tinyurl.com', 'goo.gl'];

const isLinkSuspicious = url => {
  try {
    const parsedUrl = new URL(url.startsWith('http') ? url : `http://${url}`);
    const domain = parsedUrl.hostname;
    const extension = url
      .split('.')
      .pop()
      .toLowerCase();
    const protocol = parsedUrl.protocol;
    const path = parsedUrl.pathname;
    const query = parsedUrl.search;

    const domainIsSuspicious = suspiciousDomains.some(d => domain.includes(d));
    const extensionIsSuspicious = suspiciousExtensions.includes(`.${extension}`);
    const protocolIsSuspicious = suspiciousProtocols.includes(protocol);
    const pathIsSuspicious = suspiciousPathPatterns.some(pattern => path.includes(pattern));
    const queryIsSuspicious = query.includes('download') || query.includes('free');
    const isShortenedUrl = urlShorteners.some(shortener => domain === shortener);

    return (
      domainIsSuspicious ||
      extensionIsSuspicious ||
      protocolIsSuspicious ||
      pathIsSuspicious ||
      queryIsSuspicious ||
      isShortenedUrl
    );
  } catch (error) {
    return true;
  }
};

const BulletPointsFormatter = ({ text }) => {
  if (!text || text?.length === 0 || typeof text !== 'string') {
    return <></>;
  }

  const bulletPointChars = new Set(['-', '•', '+']);

  const createLinkElement = (url, text, key) => {
    const isSuspicious = isLinkSuspicious(url);
    return (
      <a
        key={key}
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className={`${css.link} ${isSuspicious ? css.suspiciousLink : ''}`}
        title={isSuspicious ? 'This link may be suspicious' : ''}
      >
        {text}
      </a>
    );
  };

  const processTextFormatting = str => {
    const parts = [];
    let currentIndex = 0;

    // Define all patterns
    const patterns = [
      {
        regex: /(\*\*|__)(.+?)\1/g,
        process: match => <strong key={parts.length}>{match[2]}</strong>,
      },
      {
        regex: /(\*|_)(?!\1)(.+?)\1/g,
        process: match => <em key={parts.length}>{match[2]}</em>,
      },
      {
        regex: /\[(.+?)\]\((.+?)\)/g,
        process: match => createLinkElement(match[2], match[1], parts.length),
      },
      {
        regex: /(https?:\/\/[^\s]+)|(www\.[^\s]+)|(?<=\s|^)(\S+\.(?:com|org|net|edu|gov)\S*)/g,
        process: match => {
          const url = match[0].startsWith('http') ? match[0] : `http://${match[0]}`;
          return createLinkElement(url, match[0], parts.length);
        },
      },
    ];

    while (currentIndex < str.length) {
      let earliestMatch = null;
      let earliestPattern = null;
      let earliestIndex = str.length;

      // Find the earliest matching pattern
      for (const pattern of patterns) {
        pattern.regex.lastIndex = currentIndex;
        const match = pattern.regex.exec(str);
        if (match && match.index < earliestIndex) {
          earliestMatch = match;
          earliestPattern = pattern;
          earliestIndex = match.index;
        }
      }

      if (earliestMatch) {
        // Add text before the match
        if (earliestIndex > currentIndex) {
          parts.push(str.slice(currentIndex, earliestIndex));
        }
        // Process the match
        parts.push(earliestPattern.process(earliestMatch));
        currentIndex = earliestIndex + earliestMatch[0].length;
      } else {
        // Add remaining text
        parts.push(str.slice(currentIndex));
        break;
      }
    }

    return parts;
  };

  const formattedLines = text.split('\n').map((line, index) => {
    const trimmedLine = line.trim();
    if (bulletPointChars.has(trimmedLine.charAt(0))) {
      const [bullet, ...rest] = trimmedLine.split(/\s+/);
      return (
        <div key={index} className={css.bulletPoint}>
          {bullet} {processTextFormatting(rest.join(' '))}
        </div>
      );
    }
    return <div key={index}>{processTextFormatting(trimmedLine)}</div>;
  });

  return <div className={css.formattedText}>{formattedLines}</div>;
};

export default BulletPointsFormatter;
