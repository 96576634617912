import React, { useEffect, useRef, useState } from 'react';
import loadable from '@loadable/component';
import css from './ListingVideo.module.css';
import { FormattedMessage } from '../../../../util/reactIntl';

// Use loadable to import react-player without SSR
const ReactPlayer = loadable(() => import('react-player'), {
  ssr: false,
});

const ListingVideo = props => {
  // Track if component is mounted in the browser
  const [isBrowser, setIsBrowser] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const videoPlayerRef = useRef(null);

  // Set isBrowser flag once on mount (client-only)
  useEffect(() => {
    setIsBrowser(true);
  }, []);

  // Handle window resize to determine mobile view
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 576);
    window.addEventListener('resize', handleResize);
    // Run once on mount
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Extract video link safely from props
  const videoLink =
    props?.currentListing?.attributes?.publicData?.youtubeLink || null;
  const isExperienceType =
    props.currentListing.attributes?.publicData?.listingType === 'impact-experience';

  const triggerDataElbAction = () => {
    if (videoPlayerRef.current) {
      const event = new MouseEvent('click', { bubbles: true });
      videoPlayerRef.current.dispatchEvent(event);
    }
  };

  // Prevent rendering on the server
  if (!isBrowser) return null;

  return videoLink ? (
    <div className={css.videoSectionWrapper}>
      <h2 className={css.videoSectionTitle}>
        {isExperienceType ? (
          <FormattedMessage id="listingPage.youtubeExperienceLabel" />
        ) : (
          <FormattedMessage id="listingPage.videoSection.title" />
        )}
      </h2>
      <div
        className={css.videoPlayer}
        data-elbaction="click:click ytvideostart"
        ref={videoPlayerRef}
      >
        <ReactPlayer
          controls
          width="100%"
          height={isMobile ? '202px' : '609px'}
          url={videoLink}
          onPlay={triggerDataElbAction}
        />
      </div>
    </div>
  ) : null;
};

export default ListingVideo;
